import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href="https://github.com/iTXTech/mirai-webui">
                mirai-webui
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export function StickyFooter(){
    return <Box
        component="footer"
        sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
        }}
    >
        <Container maxWidth="lg">
            <Copyright />
        </Container>
    </Box>
}

export default StickyFooter